export const SunIcon = ({ ...props }) => (
  <svg
    fill="none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    role="img"
    stroke="currentColor"
    aria-label="sun"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
      fill="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);
