export const MoonIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    stroke="currentColor"
    aria-label="moon"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    {...props}
  >
    <path
      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
      fill="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);
