import type { NextWebVitalsMetric } from 'next/app';

const DEBUG_GA = false;
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

export const pageview = (url: string) => {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
  if (process.env.NODE_ENV === 'production' || DEBUG_GA) {
    window.gtag('config', GA_MEASUREMENT_ID, {
      page_path: url,
    });
  }
};

export const event = (action: string, eventObj?: Object) => {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  if (process.env.NODE_ENV === 'production' || DEBUG_GA) {
    window.gtag('event', action, eventObj);
  }
};

export const error = (eventObj?: Object) => {
  event('exception', eventObj);
};

export const webVitals = (metric: NextWebVitalsMetric) => {
  // https://nextjs.org/docs/advanced-features/measuring-performance#sending-results-to-analytics
  event(metric.name, {
    eventCategory: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    eventAction: metric.name,
    eventValue: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
    eventLabel: metric.id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
};

export const trackingCode = () => {
  // https://github.com/vercel/next.js/tree/canary/examples/with-google-analytics
  return (
    (process.env.NODE_ENV === 'production' || DEBUG_GA) && (
      <>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}', {
            page_path: window.location.pathname,
          });
        `,
          }}
        />
      </>
    )
  );
};
