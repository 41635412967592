export const AirplaneIcon = ({ fill = 'currentColor', size = 0, isDark = true, ...props }) => {
  const height = size || 24;
  const width = size || 24;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 486.736 486.736"
      fill={fill ? fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M481.883,61.238l-474.3,171.4c-8.8,3.2-10.3,15-2.6,20.2l70.9,48.4l321.8-169.7l-272.4,203.4v82.4c0,5.6,6.3,9,11,5.9l60-39.8l59.1,40.3c5.4,3.7,12.8,2.1,16.3-3.5l214.5-353.7C487.983,63.638,485.083,60.038,481.883,61.238z"
        fill={isDark ? '#fff' : '#24292f'}
      />
    </svg>
  );
};
