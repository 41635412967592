import { Container, Spacer, Text } from '@nextui-org/react';
import NowPlaying from 'components/nowPlaying';

export const footerHeight = '80px';

const Footer = () => {
  return (
    // TODO(rikhil): consider rewriting this as navbar
    <Container
      as="footer"
      display="flex"
      alignItems="center"
      justify="center"
      direction="column"
      css={{
        zIndex: 200,
        boxShadow: 'red',
        minWidth: '100vw',
        height: footerHeight,
        borderTop: '$$navbarBorderWeight solid $$navbarBorderColor',
        textAlign: 'center',
      }}
    >
      <Text
        css={{
          maxWidth: `calc(100% - 100px)`,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        © Developer RSquared
      </Text>
      <Spacer y={0.1} />
      <NowPlaying />
    </Container>
  );
};

export default Footer;
