import Script from 'next/script';

export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
const DEBUG_GTM = false;

export const registerGoogleTagManager = () => {
  return (
    (process.env.NODE_ENV === 'production' || DEBUG_GTM) && (
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
        `,
        }}
      />
    )
  );
};

export const tagManagerCode = () => {
  // https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
  // https://developers.google.com/tag-platform/tag-manager/web
  return (
    (process.env.NODE_ENV === 'production' || DEBUG_GTM) && (
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    )
  );
};
