import useSWR from 'swr';

import { Spacer, Text } from '@nextui-org/react';
import { SpotifyIcon } from 'components/images';
import fetcher from 'lib/fetcher';
import { NowPlayingSong } from 'pages/api/now-playing';

const NowPlaying = () => {
  const { data } = useSWR<NowPlayingSong>('/api/now-playing', fetcher);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SpotifyIcon />
      <Spacer x={0.3} />
      {data?.songUrl ? (
        <a
          style={{
            maxWidth: `calc(100% - 100px)`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          href={data.songUrl}
          aria-label={`Currently listening to ${data.title} on Spotify`}
          title={`Currently listening to ${data.title} on Spotify`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.title}
        </a>
      ) : (
        <Text>Not Playing</Text>
      )}
      <Spacer x={0.3} />
      <Text>-</Text>
      <Spacer x={0.3} />
      <Text>Spotify</Text>
    </div>
  );
};

export default NowPlaying;
