import { Button, Container, Text } from '@nextui-org/react';
import PageContainer from 'components/pageContainer';
import * as gtag from 'lib/gtag';
import * as React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can use your own error logging service here
    gtag.error({
      name: error.name,
      description: error.message,
      // stack: errorInfo.componentStack,
      fatal: true, // set to true if the error is fatal
    });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageContainer css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Container display="flex" direction="column" alignItems="center">
            <Text h3>Oops, there is an error!</Text>
            <Button type="button" onPress={() => this.setState({ hasError: false })}>
              Try again?
            </Button>
          </Container>
        </PageContainer>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
